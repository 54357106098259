<template>
  <el-card
    v-loading="isLoading"
    shadow="always"
    :body-style="{ padding: '0px' }"
  >
    <template #header>
      <el-row type="flex" justify="space-between" align="space-between">
        <h4>{{ $route.name }}</h4>
      </el-row>
    </template>
    <el-descriptions border v-loading="isLoadingFilter">
      <template #title> <div class="pl-1">Filtrar contas</div> </template>
      <el-descriptions-item label="Empresa:">
        <el-select
          clearable
          :loading="!firms"
          value-key="uid"
          v-model="filterOptions.firm_id"
          size="medium"
        >
          <el-option
            v-for="item in Firms"
            :key="item.uid"
            :label="item.name"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="Total de meses no filtro:">
        <base-input v-model="totalMonths"></base-input>
      </el-descriptions-item>
      <el-descriptions-item label="Data:">
        <el-select
          clearable
          value-key="uid"
          v-model="filterOptions.dateColumn"
          size="medium"
        >
          <el-option
            v-for="item in [
              { label: 'Data vencimento', value: 'expires_at' },
              { label: 'Data aquisição', value: 'bought_at' },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>

      <el-descriptions-item label="Valor:">
        <el-select
          clearable
          value-key="uid"
          v-model="filterOptions.amountColumn"
          size="medium"
        >
          <el-option
            v-for="item in [
              { label: 'Valor parcela', value: 'total_amount' },
              { label: 'Valor pago', value: 'paid_amount' },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="Tipo:">
        <el-button-group class="ml-4">
          <el-button
            :type="isButtonTypeSelected('investiment') ? 'primary' : null"
            @click="
              isButtonTypeSelected('investiment')
                ? unselectType('investiment')
                : selectType('investiment')
            "
            >Investimento</el-button
          >
          <el-button
            :type="isButtonTypeSelected('expense') ? 'primary' : null"
            @click="
              isButtonTypeSelected('expense')
                ? unselectType('expense')
                : selectType('expense')
            "
            >Despesa</el-button
          >
          <el-button
            :type="isButtonTypeSelected('cost') ? 'primary' : null"
            @click="
              isButtonTypeSelected('cost')
                ? unselectType('cost')
                : selectType('cost')
            "
            >Custo</el-button
          >
        </el-button-group>
      </el-descriptions-item>
      <el-descriptions-item label="Filtrar:">
        <el-button-group class="ml-4">
          <el-button
            plain
            type="warning"
            @click="() => (filterOptions = {} | filterBills())"
            >Limpar</el-button
          >
          <el-button type="primary" @click="() => filterBills()"
            >Adicionar filtro</el-button
          >
        </el-button-group>
      </el-descriptions-item>
    </el-descriptions>

    <VueApexCharts
      :style="{ maxHeight: '500px' }"
      v-if="Object.keys(Sums)?.length"
      :options="getBarOptions()"
      :series="IncomesAndBills"
    ></VueApexCharts>
  </el-card>
</template>
<script>
import IncomeService from "../services/incomes";
import {
  currencyFormatter,
  dateFormatterMonthYear,
  dateFormatterAPI,
} from "../utils/formatters";
import { graphLocales } from "../utils/formatters";
import VueApexCharts from "vue3-apexcharts";
import BillService from "../services/bills";
import BaseInput from "../components/BaseInput.vue";
export default {
  name: "AccountabilityReportPage",
  data() {
    return {
      compiledCosts: null,
      isLoading: true,
      isLoadingFilter: false,
      showAddIncome: [],
      incomes: null,
      totalMonths: 16,
      incomeInput: 0,
      filterOptions: {
        currentYear: new Date(),
        dateColumn: "expires_at",
        amountColumn: "total_amount",
      },
      firms: null,
      sums: {},
      currencyFormatter,
    };
  },
  components: { VueApexCharts, BaseInput },
  computed: {
    DateMonths() {
      const monthsArray = Array.from({ length: this.totalMonths });

      return monthsArray
        .map((_, i) => {
          const d = new Date();
          d.setMonth(d.getMonth() - i);

          return d;
        })
        .reverse();
    },
    Incomes() {
      return this.incomes || {};
    },
    Firms() {
      return this.firms || [];
    },
    Cached() {
      return this.compiledCosts || [];
    },
    Sums() {
      return this.sums || {};
    },
    Result() {
      const result = [];
      for (let i = 0; i < this.Incomes.length; i++) {
        result.push(this.Incomes[i] - this.Sums[i]);
      }

      return result;
    },
    IncomesAndBills() {
      return [
        {
          name: "Receitas",
          type: "column",
          data: this.Incomes,
        },
        {
          name: "Contas",
          type: "column",
          data: this.Sums,
        },
        {
          name: "Resultado",
          type: "line",
          data: this.Result,
        },
      ];
    },
  },
  mounted() {
    this.fetchIncomes();
    this.fetchSums();
    this.fetchFirms();
  },
  methods: {
    getBarOptions() {
      return {
        chart: {
          height: 500,
          type: "line",
          zoom: {
            enabled: false,
          },
          locales: graphLocales(),
          defaultLocale: "pt-br",
        },
        colors: ["#81c784", "#e57373", "#7986cb"],
        dataLabels: {
          enabled: false,
        },
        series: this.IncomesAndBills,
        legend: {
          formatter: (label) => label,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: this.DateMonths.map((date) =>
            dateFormatterMonthYear.format(date)
          ),
        },
        yaxis: {
          labels: {
            formatter: (v) => currencyFormatter.format(Number(v) || 0),
          },
        },
      };
    },
    fetchFirms() {
      const url = new URL(`${this.$store.state.apiUrl}firms`);

      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.firms = json;
        });
    },
    isButtonTypeSelected(type) {
      return this.filterOptions?.costType?.includes(type);
    },
    selectType(type) {
      if (!Array.isArray(this.filterOptions.costType))
        this.filterOptions.costType = [];

      this.filterOptions.costType.push(type);
    },
    unselectType(type) {
      if (this.isButtonTypeSelected(type))
        this.filterOptions.costType = this.filterOptions.costType?.filter(
          (t) => t != type
        );
    },
    async filterBills() {
      this.isLoadingFilter = true;

      const queries = [this.fetchIncomes(), this.fetchSums()];

      await Promise.all(queries);

      this.isLoadingFilter = false;
    },
    async fetchIncomes() {
      const queries = this.DateMonths.map((dateMonth) => {
        return IncomeService().index({
          ref_year: dateMonth.getFullYear(),
          ref_month: dateMonth.getMonth(),
          firm_id: this.filterOptions?.firm_id || "",
        });
      });

      const result = await Promise.all(queries);

      if (result)
        this.incomes = result
          .map(({ incomes }) => incomes)
          .flat()
          .map(({ amount }) => Number(amount));
    },
    async fetchSums() {
      const queries = this.DateMonths.map((dateMonth) =>
        this.fetchBills({
          [`${this.filterOptions.dateColumn}_start`]: dateFormatterAPI.format(
            new Date(dateMonth.getFullYear(), dateMonth.getMonth(), 1, 0, 0, 0)
          ),
          [`${this.filterOptions.dateColumn}_end`]: dateFormatterAPI.format(
            new Date(
              dateMonth.getFullYear(),
              dateMonth.getMonth() + 1,
              -1,
              23,
              29,
              9999
            )
          ),
          firm_id: this.filterOptions?.firm_id || "",
          costType: this.filterOptions?.costType || "",
        })
      );

      const results = await Promise.all(queries);

      this.sums = results
        .flat()
        .map((result) => result[this.filterOptions.amountColumn]);

      this.isLoading = false;
    },
    async fetchBills(filters) {
      const { bills } = await BillService().sum(filters);

      if (bills) return bills;
      else return [];
    },
  },
};
</script>
<style>
.tree-class .cell {
  display: flex;
  justify-content: left;
  background-color: white;
}
</style>
<style scoped>
.center-loading {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.el-descriptions .el-descriptions__title {
  padding-left: 16px;
}

.pl-1 {
  padding-left: 10px;
  padding-top: 8px;
}

.p-0 {
  padding: 0px !important;
}
</style>

<style>
.el-table__append-wrapper {
  overflow: visible !important;
}
.remove-label {
  display: none;
}
.max-col-13 {
  max-width: 7.692%;
}
.reduce-size {
  font-size: 10px !important;
}
.upper-amount {
  color: green;
}
.lower-amount {
  color: red;
}
</style>
