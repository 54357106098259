import http from "./http";
export default (billId) => ({
  async index(filters) {
    return http
      .index("bills", filters)
      .then((json) => ({ bills: json }))
      .catch(async (e) => ({ bills: null, error: await e }));
  },
  async sum(filters) {
    return http
      .index("bills/sum", filters)
      .then((json) => ({ bills: json }))
      .catch(async (e) => ({ bills: null, error: await e }));
  },
  async get(filters) {
    return http
      .get("bills", billId, filters)
      .then((json) => ({ bill: json }))
      .catch(async (e) => ({ bill: null, error: await e }));
  },
  async create(data) {
    return http
      .post("bills", data)
      .then((json) => ({ bill: json }))
      .catch(async (e) => ({ bill: null, error: await e }));
  },
  async update(data) {
    return http
      .put("bills", billId, data)
      .then((json) => ({ bill: json }))
      .catch(async (e) => ({ bill: null, error: await e }));
  },
  async delete() {
    return http
      .delete("bills", billId)
      .then((json) => ({ bill: json }))
      .catch(async (e) => ({ bill: null, error: await e }));
  },
});
